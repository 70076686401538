import React from "react";
import { NavLink } from "react-router-dom";

const NavCaseTeho = () => {
  return (
    <div className="nav-case">
      <div className="goDown">
        <i className="fI fas fa-chevron-down "></i>
        <i className="sI fas fa-chevron-down "></i>
      </div>
      <NavLink
        exact
        to="/teho/music"
        title="Music section"
        className="nav-musicT"
      >
        <h3>MUSIC</h3>
      </NavLink>

      <NavLink
        exact
        to="/teho/video"
        title="Artists section"
        className="nav-video"
      >
        <h3>VIDEO</h3>
      </NavLink>

      <NavLink
        exact
        to="/teho/tour-dates"
        title="Events section"
        className="nav-tour-dates"
      >
        <h3>TOUR DATES</h3>
      </NavLink>

      <a
        href="https://teho.bandcamp.com/"
        rel="noopener noreferrer"
        target="_blank"
        title="Shop Labo T"
        className="nav-shopT"
      >
        <h3>SHOP</h3>
      </a>
    </div>
  );
};

export default NavCaseTeho;
