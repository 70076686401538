import axios from "axios";
import React, { useEffect, useState } from "react";
import TehoFooter from "../tehoComponents/TehoFooter";
import TehoHeader from "../tehoComponents/TehoHeader";

const TehoTourDates = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//widget.songkick.com/4088811/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <TehoHeader />
      <main className="TourDate">
        <div className="title-groupe">
          <h1 className="title-page">TOUR</h1>
        </div>
        <div className="img-tourdate">
          <img
            src={
              process.env.PUBLIC_URL + "/img/tourDateTeho/TourDate-Teho-1.jpg"
            }
            alt="Teho work"
          />
        </div>
        <div className="wall-tourDate">
          <div className="container-widget">
            <a
              href="https://www.songkick.com/artists/4088811"
              class="songkick-widget"
              target="_blank"
              data-theme="light"
              data-track-button="on"
              data-detect-style="true"
              data-background-color="transparent"
              data-locale="en"
            >
              Teho - Tour Dates
            </a>
          </div>
        </div>
      </main>
      <TehoFooter />
    </>
  );
};

export default TehoTourDates;
