import React, { useEffect, useState } from "react";
import CardEvent from "../components/event/CardEvent";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { eventTableau } from "../event-tableau";

const Events = () => {
  const [event, setEvent] = useState([]);

  useEffect(() => {
    setEvent(eventTableau);
  }, [event]);

  return (
    <>
      <Header />
      <main className="event">
        <div className="title-groupe">
          <h1 className="title-page">EVENTS</h1>
        </div>

        <ul className="wall-event">
          {event.map((event, key) => {
            return <CardEvent event={event} key={event.id} />;
          })}
        </ul>
      </main>
      <Footer />
    </>
  );
};

export default Events;
