import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const TehoHeader = () => {
  const [header, setHeader] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const changeScrollHeader = () => {
    if (window.scrollY >= 85) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeScrollHeader);
  return (
    <header className={header ? "header active" : "header"}>
      <NavLink
        exact
        to="/teho"
        title="Home"
        activeClassName="header-activ"
        onClick={() => {
          setShowLinks(false);
        }}
      >
        <img
          className="logo-teho"
          src={process.env.PUBLIC_URL + "/img/TEHO-logo.png"}
          alt="logo Labo T, electronic music label"
        />
      </NavLink>

      <nav>
        <ul className={showLinks ? "show-bar" : null}>
          <li>
            <NavLink
              exact
              to="/teho"
              title="Home"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>HOME</h3>
            </NavLink>
          </li>
          <li>
            <Link
              smooth
              to="/teho#about"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>ABOUT</h3>
            </Link>
          </li>
          <li>
            <NavLink
              exact
              to="/teho/music"
              title="Music"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>MUSIC</h3>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/teho/video"
              title="Video"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>VIDEO</h3>
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/teho/tour-dates"
              title="Tour Dates"
              activeClassName="header-activ"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>TOUR DATES</h3>
            </NavLink>
          </li>
          <li>
            <a
              href="https://teho.bandcamp.com/"
              rel="noopener noreferrer"
              target="_blank"
              title="Shop"
              onClick={() => {
                setShowLinks(false);
              }}
            >
              <h3>SHOP</h3>
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/Tehomusic/"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/teho_live/"
              target="_blank"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/c/Teholive"
              target="_blank"
              rel="noopener noreferrer"
              title="Youtube"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://open.spotify.com/artist/7uZckwZIIL89Paj8E1GEFO"
              target="_blank"
              rel="noopener noreferrer"
              title="Spotify"
            >
              <i className="fab fa-spotify"></i>
            </a>
            <a
              href=" https://soundcloud.com/teho"
              target="_blank"
              rel="noopener noreferrer"
              title="Soundcloud"
            >
              <i className="fab fa-soundcloud"></i>
            </a>

            <a
              href="https://teho.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Bandcamp"
            >
              <i className="fab fa-bandcamp"></i>
            </a>
          </li>
        </ul>
        <button className="navbar-burger" onClick={handleShowLinks}>
          <span className={showLinks ? "cross-bar" : null}></span>
        </button>
      </nav>
    </header>
  );
};

export default TehoHeader;
