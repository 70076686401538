import React from "react";

const CardMusicTeho = ({ musicTeho }) => {
  return (
    <li
      className="card-container"
      key={musicTeho.id}
      alt={`${musicTeho.titre}` + "by" + `${musicTeho.artist}`}
    >
      <a
        href={musicTeho.lien}
        target="_blank"
        className="cover-click"
        rel="noreferrer"
      >
        {" "}
        <img
          src={musicTeho.cover}
          alt={"Artwork " + `${musicTeho.artist}` + "-" + `${musicTeho.titre}`}
        />
      </a>

      <div className="footer-card">
        <div className="info-music">
          <h3>{musicTeho.titre}</h3>
          <p>{musicTeho.label}</p>
          <p>{musicTeho.artist}</p>
        </div>
        <a
          href={musicTeho.lien}
          target="_blank"
          className="boutton-stream"
          title={"Play " + `${musicTeho.titre}` + "by" + `${musicTeho.artist}`}
          rel="noreferrer"
        >
          <img
            src={process.env.PUBLIC_URL + "/img/music/bouton-dl-stream.png"}
            alt={
              "Download and stream" +
              `${musicTeho.titre}` +
              "by" +
              `${musicTeho.artist}`
            }
          />
        </a>
      </div>
      <div className="lecteur-spotify">{musicTeho.lecteur}</div>
    </li>
  );
};

export default CardMusicTeho;
