export const artistTableau = [
  {
    id: "A0001",
    nom: "Teho",
    photo: `${process.env.PUBLIC_URL + "/img/artist/Teho.jpg"}`,
    lien: true,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/7uZckwZIIL89Paj8E1GEFO?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/Tehomusic",
    instagram: "https://www.instagram.com/teho_live/",
    soundcloud: "soundcloud.com/teho",
  },
  {
    id: "A0002",
    nom: "Romain Garcia",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/RomainGarcia.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/7iCW4xyVOIklzZ2qc7pS5h?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/iamromaingarcia",
    instagram: "https://www.instagram.com/iamromaingarcia",
    soundcloud: "https://soundcloud.com/romaingarcia",
  },
  {
    id: "A0003",
    nom: "Aalson",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/Aalson.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/2tL0yAKQDnj1zb4V9uLeOY?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/aalsonmusic/",
    instagram: "https://www.instagram.com/aalson_music/",
    soundcloud: "https://soundcloud.com/aalsonmusic",
  },
  {
    id: "A0004",
    nom: "Makoto San",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/MakotoSan.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/5q59mwaDz1bIPUdymI1Qvr?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/makotosanmusic/",
    instagram: "https://www.instagram.com/makotosanmusic",
    soundcloud: "https://soundcloud.com/user-371335098",
  },
  {
    id: "A0005",
    nom: "Ceas",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/Ceas.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/6poVmwB0wQp0blleNlIUKM?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/ceas.music/",
    instagram: "https://www.instagram.com/ceas.off/",
    soundcloud: "https://soundcloud.com/ceasmusic",
  },
  {
    id: "A0006",
    nom: "Joris Delacroix",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/Joris-Delacroix.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/3HRRzIZNQFus3xlUx2xKy1?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/jorisdelacroix/",
    instagram: "https://www.instagram.com/joris_delacroix/",
    soundcloud: "https://soundcloud.com/jorisdelacroix",
  },
  {
    id: "A0007",
    nom: "Mashk",
    photo: `${process.env.PUBLIC_URL + "/img/artist/Mashk.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/5kB9WpgrgUoKxmknyQpGHW?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/djmashk/",
    instagram: "https://www.instagram.com/mashk_official/",
    soundcloud: "https://soundcloud.com/mashk",
  },
  {
    id: "A0008",
    nom: "Minorah",
    photo: `${process.env.PUBLIC_URL + "/img/artist/Minorah.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/53gHfh8K1yaYEnrwh9EEwJ?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/Minorah.music",
    instagram: "https://www.instagram.com/minorah.music//",
    soundcloud: "https://soundcloud.com/minorah",
  },
  {
    id: "A0009",
    nom: "Polygraph",
    lien: false,
    photo: `${process.env.PUBLIC_URL + "/img/artist/Polygraph.jpg"}`,
    lecteur: (
      <iframe
        src="https://open.spotify.com/embed/artist/6gYJmeuuoyVX5E9ONHBfRL?utm_source=generator&amp;theme=0"
        width="100%"
        height="80"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="lecteur spotify"
      ></iframe>
    ),
    facebook: "https://www.facebook.com/polygraphofficial",
    instagram: "https://www.instagram.com/polygraph.live",
    soundcloud: "https://soundcloud.com/polygraph-music",
  },
];
