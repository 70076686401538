import React from "react";
import TehoFooter from "../tehoComponents/TehoFooter";
import TehoHeader from "../tehoComponents/TehoHeader";

const TehoVideo = () => {
  return (
    <>
      <TehoHeader />
      <main className="video">
        <div className="title-groupe">
          <h1 className="title-page">VIDEO</h1>
        </div>

        <div className="wall-video">
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ipY8eAynKPQ"
            title="Teho - Continuum (Live at Zenith de Toulouse)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

<iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7DgVHBrsOx0"
            title="Teho & Makoto San - Hanten (Live at Factory)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

<iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UkSWeL6PTN0"
            title="Teho & Romain Garcia - Day Off (Official music video)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>


<iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/EHhsq9jbVj0"
            title="Teho à coeur ouvert - Meet & Greet"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/qHgj8UfGjsU"
            title="Teho & Joris Delacroix - Fury - Live version"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/PxUDNSl-vRM"
            title="Teho live from Glacier 3000, Switzerland | Cercle Stories"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VP8lIPVWhuI"
            title="Teho - Polytone Live"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/EZ5B6QkhSoU"
            title="Teho live @ Colorado Provencal in Rustrel, France for Cercle"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/l7imvew3jos"
            title="Native Sessions : Produire de la techno envoûtante avec Teho sur Maschine | Native Instruments"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ttZ9u618CPw"
            title="Teho - Union (Official Music Video)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </main>
      <TehoFooter />
    </>
  );
};

export default TehoVideo;
