import React, { useEffect, useState } from "react";
import TehoFooter from "../tehoComponents/TehoFooter";
import TehoHeader from "../tehoComponents/TehoHeader";

import { musicTableauTeho } from "../DB-teho/music-tableau-teho";
import CardMusicTeho from "../tehoComponents/musicTeho/CardMusicTeho";

const TehoMusic = () => {
  const [loadPost, setLoadPost] = useState(true);
  const [musicTeho, setMusicTeho] = useState([]);
  const [count, setCount] = useState(12);

  const loadMore = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 180 >
      document.scrollingElement.scrollHeight
    ) {
      setLoadPost(true);
    }
  };

  useEffect(() => {
    if (loadPost) {
      setMusicTeho(musicTableauTeho.slice(0, count));
      setLoadPost(false);
      setCount(count + 12);
    }

    window.addEventListener("scroll", loadMore);
    return () => window.removeEventListener("scroll", loadMore);
  }, [musicTeho, loadPost, count]);

  console.log(musicTeho);
  return (
    <>
      <TehoHeader />
      <main className="music">
        <div className="title-groupe">
          <h1 className="title-page">MUSIC</h1>
        </div>

        <ul className="wall-music">
          {musicTeho.map((musicTeho, key) => {
            return <CardMusicTeho musicTeho={musicTeho} key={musicTeho.id} />;
          })}
        </ul>
      </main>
      <TehoFooter />
    </>
  );
};

export default TehoMusic;
