import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
// import Header from "../Header";
import Home from "../../pages/Home";
import Music from "../../pages/Music";
import Artists from "../../pages/Artists";
import Events from "../../pages/Events";
// import Footer from "../Footer";
import ScrollToTop from "../scrollToTop";
import homeTeho from "../../Teho/pages/homeTeho";
import TehoMusic from "../../Teho/pages/TehoMusic";
import TehoVideo from "../../Teho/pages/TehoVideo";
import TehoTourDates from "../../Teho/pages/TehoTourDates";

const index = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        {/* <Header /> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/music" exact component={Music} />
          <Route path="/artists" exact component={Artists} />
          <Route path="/events" exact component={Events} />
          <Route path="/teho" exact component={homeTeho} />
          <Route path="/teho/music" exact component={TehoMusic} />
          <Route path="/teho/video" exact component={TehoVideo} />
          <Route path="/teho/tour-dates" exact component={TehoTourDates} />
          <Redirect to="/" />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </>
  );
};

export default index;
