import React, { useEffect } from "react";

const CarousselImgTeho = () => {
  var myIndex = 0;

  useEffect(() => {
    const carousel = () => {
      var i;
      var x = document.getElementsByClassName("slide");
      if (x.length > 0) {
        for (i = 0; i < x.length; i++) {
          x[i].style.width = "0";
        }
        myIndex++;
        if (myIndex > x.length) {
          myIndex = 1;
        }
        x[myIndex - 1].style.width = "100%";
        setTimeout(carousel, 3500);
      }
    };
    carousel();
  }, []);
  return (
    <div className="caroussel">
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-7.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-2.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-3.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-4.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-5.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-6.jpg"}
        alt=""
      />
    
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-8.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/carousselTeho/Teho-9.jpg"}
        alt=""
      />
    
    </div>
  );
};

export default CarousselImgTeho;
