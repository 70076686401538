import React, { useEffect, useState } from "react";
import { artistTableau } from "../artist-tableau";
import CardArtist from "../components/artist/CardArtist";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Artists = () => {
  const [artist, setArtist] = useState([]);

  useEffect(() => {
    setArtist(artistTableau);
  }, [artist]);

  return (
    <>
      <Header />
      <main className="artists">
        <div className="title-groupe">
          <h1 className="title-page">ARTISTS</h1>
        </div>

        <ul className="wall-artist">
          {artist.map((artist, key) => {
            return <CardArtist artist={artist} key={artist.id} />;
          })}
        </ul>
        <section className="other-artists">
          <div className="title-artist-remixer">
            <h4>ALL ARTISTS | REMIXERS</h4>
            <span></span>
          </div>
          <div className="all-other">
            <div className="artist a-i">
              <p>Aalson</p>
              <p>Alessandro Diga</p>
              <p>Aparde</p>
              <p>Anders.</p>
              <p>Axom</p>
              <p>Carott</p>
              <p>Ceas</p>
              <p>Clawz SG</p>
              <p>Cloud 18</p>
              <p>Corren Cavini</p>
              <p>Einmusik</p>
              <p>Fejkà</p>
              <p>Greg Terlikowski</p>
              <p>Hells Kitchen</p>
              <p>In-Dika</p>
              
            </div>
            <div className="artist j-m">
            <p>Joachim Pastor</p>
            <p>Jonas Saalbach</p>
              <p>Joris Delacroix</p>
              <p>Karmâa</p>
              <p>Koelle</p>
              <p>Lauren Mia</p>
              <p>Lumo.</p>
              <p>Lydgen</p>
              <p>Makoto San</p>
              <p>Mark Hoffen</p>
              <p>Mashk</p>
              <p>Max TenRoM</p>
              <p>Minorah</p>
              <p>Moritz Hofbauer</p>
              <p>Nihils</p>
            </div>
            <div className="artist n-w">
          
     
              <p>Nils Andreas</p>
              <p>Nuage</p>
              <p>Oxia</p>
              <p>pølaroit</p>
              <p>Polygraph</p>
              <p>Romain Garcia</p>
              <p>Ron Flatter</p>
              <p>Seth Schwarz</p>
              <p>Reig</p>
              <p>Teho</p>
              <p>Tonic Walter</p>
              <p>THe WHite SHadow</p>
              <p>Üm.</p>
              <p>Yodja</p>
              <p>We Need Cracks</p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Artists;
