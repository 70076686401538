import React from "react";
import About from "../tehoComponents/homeTeho/About";
import CarousselImgTeho from "../tehoComponents/homeTeho/CarousselImgTeho";
import NavCaseTeho from "../tehoComponents/homeTeho/NavCaseTeho";
import TehoFooter from "../tehoComponents/TehoFooter";
import TehoHeader from "../tehoComponents/TehoHeader";

const homeTeho = () => {
  document.title = "Teho | Official Website"
  return (
    <>
      <TehoHeader />
      <CarousselImgTeho />
      <NavCaseTeho />
      <About />
      <TehoFooter />
    </>
  );
};

export default homeTeho;
