import React, { useEffect } from "react";

const CarousselImg = () => {
  var myIndex = 0;

  useEffect(() => {
    const carousel = () => {
      var i;
      var x = document.getElementsByClassName("slide");
      if (x.length > 0) {
        for (i = 0; i < x.length; i++) {
          x[i].style.width = "0";
        }
        myIndex++;
        if (myIndex > x.length) {
          myIndex = 1;
        }
        x[myIndex - 1].style.width = "100%";
        setTimeout(carousel, 3500);
      }
    };
    carousel();
  }, []);

  return (
    <div className="caroussel">
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5388.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_4978.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5165.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5370.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5368.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_4969.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5311.jpg"}
        alt=""
      />
      <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5176.jpg"}
        alt=""
      />
    
       <img
        className="slide"
        src={process.env.PUBLIC_URL + "/img/caroussel/DSC_5214.jpg"}
        alt=""
      />
    </div>
  );
};

export default CarousselImg;
