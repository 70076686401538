import React from "react";
import { NavLink } from "react-router-dom";

const CardArtist = ({ artist }) => {
  return (
    <li className="card-artist-container" key={artist.id}>
      {artist.lien ? (
        <NavLink
          className="img-container"
          exact
          to="/teho"
          title="Teho"
          rel="noreferrer"
          target="_blank"
        >
          <img className="image-lien" src={artist.photo} alt={artist.nom} />
        </NavLink>
      ) : (
        <a
        className="img-container"
        href={artist.facebook}
        target="_blank"
        rel="noreferrer"
      >
        <img className="image-lien" src={artist.photo} alt={artist.nom} />
      </a>
      )}

      <div className="artist-main">
        <h3>{artist.nom}</h3>
        <div className="artist-reseaux">
          <a
            href={artist.facebook}
            target="_blank"
            rel="noreferrer"
            title={"Facebook " + `${artist.nom}`}
          >
            <i className="fab fa-facebook-square"></i>
          </a>

          <a
            href={artist.instagram}
            target="_blank"
            rel="noreferrer"
            title={"Instagram " + `${artist.nom}`}
          >
            <i className="fab fa-instagram"></i>
          </a>

          <a
            href={artist.soundcloud}
            target="_blank"
            rel="noreferrer"
            title={"Soundcloud" + `${artist.nom}`}
          >
            <i className="fab fa-soundcloud"></i>
          </a>
        </div>
      </div>
      {artist.lecteur}
    </li>
  );
};

export default CardArtist;
